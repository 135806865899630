<template>
  <div>
    <b-row class="no-gutters justify-content-end">
      <b-col md="6">
        <b-input-group>
          <b-form-input
            placeholder="Search Name"
            v-model="filter.search"
            @keyup.enter="handleSearchProductList"
          ></b-form-input>
          <b-input-group-append>
            <b-input-group-text>
              <b-iconstack
                font-scale="2"
                type="submit"
                @click.prevent="handleSearchProductList"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-3">
        <b-table
          responsive
          striped
          :fields="fields"
          :items="productList"
          :busy="isBusy"
          :per-page="filter.perPage"
          :current-page="filter.pageNo"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(id)="data">
            <b-form-checkbox
              size="lg"
              class="ml-3"
              :value="data.item.id"
              v-model="productSelectedList"
              @change="checkActive(data.item)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(code)>
            <div class="d-flex justify-content-center">
              <b-img
                class="box-image-component1-1"
                :src="default_image"
                fluid
                @error="default_image"
              ></b-img>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div>{{ data.item.name }}</div>
            <small>
              {{ data.item.ah1_name }} > {{ data.item.ah2_name }} >
              {{ data.item.ah3_name }} > {{ data.item.ah4_name }}</small
            >
          </template>
          <template v-slot:cell(sort_order)="data">
            <b-form-input
              v-if="refreshInput"
              v-model="data.item.sort_order"
              type="number"
              :disabled="data.item.active"
              @change="addProduct(data.item)"
              placeholder="Plaease insert sort order"
            ></b-form-input>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="hanndleChangePerpage"
      @pagination="pagination"
      :filter="filter"
      :rows="rows"
    />
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    productList: {
      required: true,
      type: Object | Array,
    },
    rows: {
      required: false,
      type: Number,
    },
    idList: {
      required: false,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    showing: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      productSelectedList: [],
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "5%" },
        },
        {
          key: "code",
          label: "Image",
          thStyle: { width: "10%" },
          tdClass: "p-1",
        },
        {
          key: "name",
          label: "Product Name / Category",
          class: "text-left",
        },
      ],
      showOnly: false,
      filter: {
        take: 5,
        page: 1,
        search: "",
        productIdList: [],
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      refreshInput: true,
    };
  },
  watch: {
    IdList(val) {
      console.log(val);
    },
  },
  methods: {
    async getProductInCategory(id) {
      this.isBusy = true;

      const { data } = await this.axios(
        `/Category/GetSelectProductParentCategory/${id}`
      );

      if (data.result == 1) {
        this.isBusy = false;

        this.productSelectedList = data.detail;
      }
    },
    pagination() {
      Vue.nextTick(() => {
        this.$emit("pagination", this.filter.page);
      });
    },
    setshowOnly() {
      this.filter.page = 1;
      Vue.nextTick(() => {
        this.$emit("setShowonly", this.showOnly);
      });
    },
    handleSearchProductList(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.$emit("handleSearchProductList", this.filter.search);
        }
      } else if (e.type === "click") {
        this.$emit("handleSearchProductList", this.filter.search);
      }
    },
    hanndleChangePerpage() {
      Vue.nextTick(() => {
        this.$emit("hanndleChangePerpage", this.filter.take);
      });
    },
    onCheckDisable(index) {
      return this.PurchaseChanelList[index].active;
    },
    checkItemActive(id) {
      let target = this.PurchaseChanelList.find((el) => el.id == id);
      return target.active;
    },
    checkActive(item) {
      item.active = !item.active;
      this.refreshInput = false;
      this.$nextTick(() => {
        this.refreshInput = true;
      });
      Vue.nextTick(() => {
        this.$emit("setProductID", item);
      });
      if (item.active == true) {
        item.sort_order = 0;
        Vue.nextTick(() => {
          this.$emit("setSpliceSelectProduct", item.id);
        });
      } else {
        Vue.nextTick(() => {
          this.$emit("setSpliceDeleteProduct", item.id);
        });
      }
    },
    addProduct(list) {
      Vue.nextTick(() => {
        this.$emit("setProductID", list);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
</style>
