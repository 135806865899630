var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"btn btn-main button mb-2 btn-cat",attrs:{"type":"button"},on:{"click":_vm.createMainCategory}},[_vm._v(" Create Root Category ")]),_c('div',{staticClass:"mt-3 list-cat pb-1"},[_vm._l((_vm.items),function(item){return [_c('div',{key:`lv2-${item.id}`},[_c('div',{staticClass:"pl-lv2 py-1"},[_c('div',{class:{ menuactive: _vm.isActive(item.id) }},[_c('div',[_c('span',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${item.id}`),expression:"`collapse-${item.id}`"}],staticClass:"p-0",attrs:{"variant":"link"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.expandList.includes(`collapse-${item.id}`)
                        ? 'chevron-up'
                        : 'chevron-down'}})],1),_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.category_2.length)+") ")],1),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"toggle-tree"},on:{"click":function($event){return _vm.getCategoryData(item, 1, item, `collapse-${item.id}`)}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)])])]),_vm._l((item.category_2),function(item2){return [_c('b-collapse',{key:`lv1-${item.id}-lv2-${item2.id}`,attrs:{"id":`collapse-${item.id}`}},[_c('div',[_c('div',{staticClass:"text-left pl-lv3"},[_c('div',{staticClass:"pl-lv2-box",class:{ menuactive: _vm.isActive(item2.id) }},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${item.id}-${item2.id}`),expression:"`collapse-${item.id}-${item2.id}`"}],staticClass:"p-0",attrs:{"variant":"link"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.expandList.includes(`collapse-${item.id}-${item2.id}`)
                        ? 'chevron-up'
                        : 'chevron-down'}})],1),_c('span',[_vm._v(" "+_vm._s(item2.name)+" ("+_vm._s(item2.category_3.length)+") ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"toggle-tree"},on:{"click":function($event){return _vm.getCategoryData(
                      item2,
                      2,
                      item,
                      `collapse-${item.id}-${item2.id}`
                    )}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)])]),_vm._l((item2.category_3),function(item3){return [_c('b-collapse',{key:`lv1-${item.id}-lvl-2-${item2.id}-lvl3-${item3.id}`,attrs:{"id":`collapse-${item.id}-${item2.id}`}},[_c('div',{key:`lv1-${item.id}-lvl-2-${item2.id}-lvl3-${item3.id}`},[_c('div',{staticClass:"text-left pl-lv3"},[_c('div',{staticClass:"pl-lv3-box",class:{ menuactive: _vm.isActive(item3.id) }},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                        `collapse-${item.id}-${item2.id}-${item3.id}`
                      ),expression:"\n                        `collapse-${item.id}-${item2.id}-${item3.id}`\n                      "}],staticClass:"p-0",attrs:{"variant":"link"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.expandList.includes(
                            `collapse-${item.id}-${item2.id}-${item3.id}`
                          )
                            ? 'chevron-up'
                            : 'chevron-down'}})],1),_c('span',[_vm._v(" "+_vm._s(item3.name)+" ("+_vm._s(item3.category_4.length)+") ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"toggle-tree"},on:{"click":function($event){return _vm.getCategoryData(
                          item3,
                          3,
                          item2,
                          `collapse-${item.id}`
                        )}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)])]),_c('b-collapse',{attrs:{"id":`collapse-${item.id}-${item2.id}-${item3.id}`}},[_vm._l((item3.category_4),function(item4,key4){return [_c('div',{key:`lv1-${item.id}-lvl-2-${item2.id}-lvl3-${item3.id}-lvl4-${key4}`},[_c('div',{staticClass:"text-left pl-lv3"},[_c('div',{staticClass:"pl-lv4-box",class:{ menuactive: _vm.isActive(item4.id) },on:{"click":function($event){return _vm.getCategoryData(
                            item4,
                            4,
                            item3,
                            `collapse-${item.id}`
                          )}}},[_c('span',[_vm._v(" "+_vm._s(item4.name)+" ")])])])])]})],2)],1)]})],2)]})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }